<template>
  <div class="tc-footer-container">
    <h1>Tunescrib Dev</h1>
    <h2>Version: 5d26d6453ac979a244cdaaa19c7a480021e47537</h2>
  </div>
</template>

<script>
export default {
  name: 'TCFooter',
};
</script>

<style scoped>
.tc-hero-title-container {
}

h1 {
  font-size: 16px;
  text-align: center;
}

h2 {
  font-size: 16px;
  text-align: center;
}
</style>
