<template>
  <div class="tc-hero-title-container">
    <p class="tc-text-logo-foo">tunescrib</p>
    <p class="tc-header-subtitle">music theory game (dev)</p>
    <div class="tc-header-basic-nav-links">
      <a href="/">Music Math Games</a>
      |
      <a href="/notes/">NEW: Learn Notes on Staff</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroTitle',
};
</script>

<style scoped>
.tc-hero-title-container {
  border-style: solid;
  border-width: 1px;
  padding: 20px;
  position: relative;
  background-color: white;
  max-width: 1000px;
  margin: auto;
  border-radius: 3px;
}

.tc-text-logo {
  font-family: 'Karla', Calibri, Verdana, Helvetica, Arial, sans-serif;
  font-size: 500%;
  text-align: center;
  margin-bottom: 0em;
  margin-top: 0em;
}
.tc-text-logo-foo {
  font-family: 'Karla', Calibri, Verdana, Helvetica, Arial, sans-serif;
  font-size: 500%;
  text-align: center;
  margin-top: 0em;
  margin-bottom: 0em;
}

.tc-header-subtitle {
  font-family: 'Karla', Calibri, Verdana, Helvetica, Arial, sans-serif;
  font-size: 200%;
  text-align: center;
  margin-top: 0em;
  margin-bottom: 1em;
}

.tc-header-basic-nav-links {
  font-family: 'Karla', Calibri, Verdana, Helvetica, Arial, sans-serif;
  font-size: 100%;
  text-align: center;
  /* color: #212121; */
  color: #007f90;
}

a:link,
a:visited {
  color: #007f90;
}
</style>
