import axios from 'axios';

export async function generateQuestion(gameKey) {
  // legacy game api URL
  // let questionApiUrl = `https://24xz74vv51.execute-api.us-west-2.amazonaws.com/test/api/question?gameKey=${gameKey}`;
  console.log(`selectedGameKey: '${gameKey}'`);
  const questionApiUrl = `https://rs-tc-container.c88kfkhf257ca.ca-central-1.cs.amazonlightsail.com/api/game/question?gameKey=${gameKey}`;

  const res = await axios.get(questionApiUrl);
  console.log(res.data);
  if (res.data) {
    return {
      question: res.data.question,
      answer: res.data.answer,
    };
  } else {
    throw new Error('could not get response from backend api');
  }
}
