<template>
  <div class="games-list-container">
    <h1>TC Math Game Games List</h1>
    <h2>Selected Game Key:</h2>
    <p>{{ selectedGameKey }}</p>

    <table class="pure-table pure-table-horizontal">
      <thead class="table-header">
        <tr>
          <th>Select</th>
          <th>Group</th>
          <th>Game Name</th>
          <th>Example</th>
        </tr>
      </thead>
      <tbody>
        <TCMathGamesListItem
          v-for="game in games"
          :key="game.id"
          :game="game"
          :selected="game.gameKey === selectedGameKey"
          @selectedGameKeyChanged="selectedGameKeyChanged"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import TCMathGamesListItem from './TCMathGamesListItem.vue';
export default {
  name: 'TCMathGamesList',
  props: ['games', 'selectedGameKey', 'gameKey'],
  components: {
    TCMathGamesListItem,
  },
  emits: ['selectedGameKeyChanged'],
  methods: {
    selectedGameKeyChanged(gameKey) {
      this.$emit('selectedGameKeyChanged', gameKey);
    },
  },
};
</script>

<style scoped>
.games-list-container {
  background-color: white;
  max-width: 1000px;
  margin: auto;
  padding: 1.5em;
  position: relative;
  border-radius: 3px;
}

.table-header {
  background: #0078e7;
}
</style>
