<template>
  <tr>
    <td>
      <label>
        <input
          type="radio"
          name="optradio"
          :value="game.gameKey"
          @change="selectionChanged"
          :checked="selected"
        />
        Select
      </label>
    </td>
    <td>{{ game.group }}</td>
    <td>{{ game.name }}</td>
    <td>{{ game.example }}</td>
  </tr>
</template>

<script>
export default {
  name: 'TCMathGamesListItem',
  props: ['game', 'selected'],
  emits: ['selectedGameKeyChanged'],
  methods: {
    selectionChanged(event) {
      const gameKey = event.target.value;
      this.$emit('selectedGameKeyChanged', gameKey);
    },
  },
};
</script>

<style scoped>
td {
  font-size: 16px;
}
</style>
