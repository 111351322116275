<template>
  <div class="page-wrap">
    <!-- <h1 style="color: white">Game Page. Selected Game Key: {{ this.gameKey }}</h1>     -->

    <HeroTitle />
    <div class="tc-vert-space"></div>
    <TCMathGamePlay :gameKey="gameKey" />
    <div class="tc-vert-space"></div>
    <TCMathGamesList
      :games="games"
      @selectedGameKeyChanged="selectedGameKeyChanged"
      :selectedGameKey="gameKey"
    />
    <TCFooter />
  </div>
</template>

<script>
import HeroTitle from '../components/HeroTitle.vue';
import TCMathGamePlay from '../components/TCMathGamePlay.vue';
import TCMathGamesList from '../components/TCMathGamesList.vue';
import TCFooter from '../components/TCFooter.vue';
import { games } from '../games-list';

const defaultGameKey = 'create_random_chord_in_major_key_question_obj';

export default {
  name: 'TCMathGamePage',
  components: {
    HeroTitle,
    TCMathGamePlay,
    TCMathGamesList,
    TCFooter,
  },
  data() {
    return {
      games,
      gameKey: defaultGameKey,
    };
  },
  methods: {
    selectedGameKeyChanged(gk) {
      console.log('TCMathGamePage::selectedGameKeyChanged()');
      console.log('before update: this.gameKey: ' + this.gameKey);
      this.gameKey = gk;
      console.log('after update: this.gameKey: ' + this.gameKey);
    },
  },
};
</script>

<style>
body {
  font-family: 'Karla', 'Lucida Grande', 'Helvetica', 'Arial';
  font-size: 20px;
  margin: 0;
  padding: 0;
  line-height: 1.3;
}

h1 {
  font-size: 1.8em;
  font-weight: 700;
}

h2 {
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 0.5em;
  margin-top: 1em;
}
</style>

<style scoped>
.page-wrap {
  background-color: #212121;
  padding: 1.5em;
}

.tc-vert-space {
  padding-top: 2em;
}
</style>
