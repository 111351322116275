export const games = [
  {
    id: '1',
    group: 'Essentials',
    name: 'Chord in Major Key',
    gameKey: 'create_random_chord_in_major_key_question_obj',
    example: 'What is the vi in D Major?',
  },
  {
    id: '2',
    group: 'Essentials',
    name: 'Chord in minor Key',
    gameKey: 'create_random_chord_in_minor_key_question_obj',
    example: 'What is the bVI in C Minor?',
  },
  {
    id: '3',
    group: 'Essentials',
    name: 'Chord Roman Numeral in Major Key',
    gameKey: 'create_random_chordrn_in_major_key_question_obj',
    example: 'In the key of A Major, what chord Roman Numeral is C#m?',
  },
  {
    id: '4',
    group: 'Essentials',
    name: 'Chord Roman Numeral in minor Key',
    gameKey: 'create_random_chordrn_in_minor_key_question_obj',
    example: 'In the key of D minor, what chord Roman Numeral is A7?',
  },
  {
    id: '5',
    group: 'Chord Construction',
    name: 'Solfege syllables in Major key chords',
    gameKey: 'create_random_solfege_syllable_in_chord_major_key_question_obj',
    example: 'What solfege syllable is the 3rd in a vi chord (Major key)?',
  },
  {
    id: '6',
    group: 'Chord Construction',
    name: 'Solfege syllables in minor key chords',
    gameKey: 'create_random_solfege_syllable_in_chord_minor_key_question_obj',
    example: 'What solfege syllable is the 3rd in a bVII chord (minor key)?',
  },
  {
    id: '7',
    group: 'Chord Construction',
    name: 'Interval types in Major key chords',
    gameKey:
      'create_random_identify_type_of_interval_in_chord_major_key_question_obj',
    example: 'What type of 3rd is in a viidim chord?',
  },
  {
    id: '8',
    group: 'Chord Construction',
    name: 'Interval types in minor key chords',
    gameKey:
      'create_random_identify_type_of_interval_in_chord_minor_key_question_obj',
    example: 'What type of 3rd is in a v chord?',
  },
  {
    id: '9',
    group: 'Relativity',
    name: 'Relative minor key',
    gameKey: 'create_random_relative_minor_key_question_obj',
    example: 'What is the relative minor key of Eb?',
  },
  {
    id: '10',
    group: 'Relativity',
    name: 'Relative Major key',
    gameKey: 'create_random_relative_major_key_question_obj',
    example: 'What is the relative Major key of Dm?',
  },
  {
    id: '11',
    group: 'Relativity',
    name: 'Chord Roman Numeral in Relative Major Key',
    gameKey:
      'create_random_relative_chordrn_in_minor_key_from_major_key_question_obj',
    example: 'IV in Major is relative to what in minor?',
  },
  {
    id: '12',
    group: 'Relativity',
    name: 'Chord Roman Numeral in Relative minor Key',
    gameKey:
      'create_random_relative_chordrn_in_major_key_from_minor_key_question_obj',
    example: 'bVI in minor is relative to what in Major?',
  },
  {
    id: '13',
    group: 'Relativity',
    name: 'Relative Solfege Syllable in Relative minor Key',
    gameKey:
      'create_random_relative_minor_from_major_solfege_syllable_question_obj',
    example: 'La in Major is relative to what in minor?',
  },
  {
    id: '14',
    group: 'Relativity',
    name: 'Relative Solfege Syllable in Relative Major Key',
    gameKey:
      'create_random_relative_major_from_minor_solfege_syllable_question_obj',
    example: 'So in minor is relative to what in Major?',
  },
  {
    id: '50',
    group: 'Solfege Oriented',
    name: 'Upgoing Intervals in Major Key',
    gameKey: 'create_random_major_solfege_up_to_solfege_interval_question_obj',
    example: 'In Major, what is the interval from So up to Do?',
  },
  {
    id: '51',
    group: 'Solfege Oriented',
    name: 'Downgoing Intervals in Major Key',
    gameKey:
      'create_random_major_solfege_down_to_solfege_interval_question_obj',
    example: 'In Major, what is the interval from Ti down to So?',
  },
  {
    id: '52',
    group: 'Solfege Oriented',
    name: 'Solfege Syllable + Interval in Major Key',
    gameKey: 'create_random_major_solfege_plus_interval_question_obj',
    example: 'What syllable is a P5 up from Mi?',
  },
  {
    id: '53',
    group: 'Solfege Oriented',
    name: 'Solfege Syllable - Interval in Major Key',
    gameKey: 'create_random_major_solfege_minus_interval_question_obj',
    example: 'What syllable is a M6 down from Mi?',
  },
  {
    id: '54',
    group: 'Solfege Oriented',
    name: 'Upgoing Intervals in minor Key',
    gameKey: 'create_random_minor_solfege_up_to_solfege_interval_question_obj',
    example: 'In minor, what is the interval from Me up to Do?',
  },
  {
    id: '55',
    group: 'Solfege Oriented',
    name: 'Downgoing Intervals in minor Key',
    gameKey:
      'create_random_minor_solfege_down_to_solfege_interval_question_obj',
    example: 'In minor, what is the interval from Me down to Te?',
  },
  {
    id: '56',
    group: 'Solfege Oriented',
    name: 'Solfege Syllable + Interval in minor Key',
    gameKey: 'create_random_minor_solfege_plus_interval_question_obj',
    example: 'What syllable is a M6 up from Me?',
  },
  {
    id: '57',
    group: 'Solfege Oriented',
    name: 'Solfege Syllable - Interval in minor Key',
    gameKey: 'create_random_minor_solfege_minus_interval_question_obj',
    example: 'What syllable is a P5 down from Te?',
  },
];
