<template>
  <div id="play-area" class="main-play-area">
    <!-- Play Stats //-->
    <div class="stats-container">
      {{ numPlays }} Plays, {{ numCorrect }} Correct,
      {{ numIncorrect }} Incorrect
    </div>

    <h1>Game Play</h1>

    <h2>Selected Game (Choose from Games List Below):</h2>
    <div class="selected-game-name">
      <p>{{ gameKey }}</p>
    </div>

    <div v-if="inPlay">
      <button class="pure-button button-finish" @click="finishClicked">
        Finish
      </button>
    </div>
    <div v-else>
      <button class="pure-button button-start" v-on:click="loadNewQuestion">
        Start!
      </button>
    </div>

    <div id="play-block">
      <div id="question-block">
        <h2>Question</h2>
        <div class="question-display">
          {{ question }}
        </div>
      </div>

      <hr />

      <div id="player-answer">
        <h2>Answer</h2>
        <div class="pure-form">
          <fieldset>
            <div id="answer-buttons">
              <input
                class="pure-input"
                v-model="userAnswer"
                v-on:keyup.enter="onAnswerEnter"
                ref="userAnswerTextbox"
              />
              <button
                class="pure-button submit-answer-button"
                v-on:click="submitAnswer"
              >
                Submit Answer
              </button>
            </div>
          </fieldset>
        </div>
      </div>

      <hr />

      <div id="result">
        <h2>Result</h2>

        <div class="result-previous-question-display">
          {{ previousQuestion }}
        </div>

        <div class="result-display">
          {{ resultEmoji }}
          {{ result }}
        </div>

        <div class="result-you-answered-display">
          {{ resultYouAnswered }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateQuestion } from '../game-api-client';
export default {
  name: 'TCMathGamePlay',
  props: ['gameKey'],
  data() {
    return {
      inPlay: false,
      question: 'Click Start!',
      userAnswer: '',
      result: '',
      resultBool: null,
      resultEmoji: '',
      resultYouAnswered: '',
      previousQuestion: '',
      numPlays: 0,
      numCorrect: 0,
    };
  },
  computed: {
    numIncorrect() {
      return this.numPlays - this.numCorrect;
    },
  },
  methods: {
    async loadNewQuestion() {
      this.inPlay = true;
      console.log(`in loadNewQuestion() - this.gameKey: ${this.gameKey}`);
      const { question, answer } = await generateQuestion(this.gameKey);
      this.question = question;
      this.answer = answer;
    },
    finishClicked() {
      this.resetPlay();
    },
    resetPlay() {
      this.inPlay = false;
      this.question = 'Click Start!';
      this.userAnswer = '';
      this.result = '';
      this.resultBool = null;
      this.resultEmoji = '';
      this.resultYouAnswered = '';
      this.previousQuestion = '';
      this.numPlays = 0;
      this.numCorrect = 0;
    },
    submitAnswer() {
      let usersAnswer = this.userAnswer.trim();
      if (usersAnswer === '') {
        return;
      }
      this.numPlays++;
      this.previousQuestion = this.question;
      if (usersAnswer === this.answer) {
        this.result = `Correct: ${this.answer}`;
        this.resultBool = true;
        this.resultEmoji = '👍';
        this.numCorrect++;
      } else {
        this.result = `Incorrect. The correct answer is: ${this.answer}`;
        this.resultYouAnswered = `You entered: ${usersAnswer}`;
        this.resultBool = false;
        this.resultEmoji = '❌'; //👎
      }
      this.userAnswer = '';
      this.loadNewQuestion();
    },
    onAnswerEnter() {
      this.submitAnswer();
    },
  },
};
</script>

<style scoped>
body {
  font-family: 'Karla', 'Lucida Grande', 'Helvetica', 'Arial';
  font-size: 20px;
  margin: 0;
  padding: 0;
  line-height: 1.3;
}

p {
  font-family: 'Karla', 'Lucida Grande', 'Helvetica', 'Arial';
  font-size: 20px;
  margin: 20;
  padding: 0;
  line-height: 1.3;
}

.question-display {
  font-family: 'Karla', 'Lucida Grande', 'Helvetica', 'Arial';
  padding-top: 0em;
  font-size: 200%;
}

.result-display {
  font-family: 'Karla', 'Lucida Grande', 'Helvetica', 'Arial';
  padding-top: 0em;
  font-size: 200%;
}

.result-you-answered-display {
  font-family: 'Karla', 'Lucida Grande', 'Helvetica', 'Arial';
  padding-top: 0em;
  font-size: 140%;
}

.result-previous-question-display {
  font-family: 'Karla', 'Lucida Grande', 'Helvetica', 'Arial';
  padding-top: 0em;
  font-size: 140%;
}

.big-emoji {
  font-family: 'Karla', 'Lucida Grande', 'Helvetica', 'Arial';
  display: inline;
  border: none;
  box-shadow: none;
  height: 5em;
  width: 5em;
  margin: 0 0.07em;
  vertical-align: -0.1em;
  background: 0 0;
  padding: 0;
}

.main-play-area {
  border-style: solid;
  border-width: 1px;
  padding: 20px;
  position: relative;
  background-color: white;
  max-width: 1000px;
  margin: auto;
  border-radius: 3px;
}

.stats-container {
  position: absolute;
  background: #8bc34a;
  top: 0px;
  right: 0px;
  width: 350px;
  height: 70;
  padding: 1em;
  border-radius: 0 3px 0 3px;
}

.button-start,
.button-finish,
.submit-answer-button {
  color: white;
  font-weight: 100;
  border-radius: 3px;
  margin: 15px;
}

.button-start {
  background: #8bc34a;
  color: black;
}

.button-finish {
  background: rgb(202, 60, 60);
}

.submit-answer-button {
  background: #0078e7;
}
</style>
